<script setup lang="ts">
import { computed } from 'vue'
import useAuthStore from '@/stores/auth/auth.store'
import UserCicle from '@/components/Avatar.vue'
import type { ChatMessage } from '@papershift/api/src/chat'
import { useLocalizedDate } from '@/views/composables/use-localized-date'
import { nameToInitials } from '@/utils/initials'
import { Tippy } from 'vue-tippy'

const props = defineProps<{
  message: ChatMessage
  isFollowUp: boolean
}>()

const authStore = useAuthStore()
const { formatDate } = useLocalizedDate()

const self = computed(() => props.message.author_id === authStore.user!.id)
const createdAt = computed(() => formatDate(props.message.created_at, 'HH:mm'))
</script>

<template>
  <div class="select-none">
    <div class="xs:mb-6 md:mb-5 flex" :class="{ 'justify-end': self }">
      <div class="mr-4" :class="{ 'ml-9': isFollowUp }">
        <Tippy placement="left-start" :content="message.author?.name">
          <UserCicle
            v-if="!self && !isFollowUp"
            :initials="nameToInitials(message.author?.name)"
            class="w-9 h-9"
          />
        </Tippy>
      </div>

      <div class="flex items-end">
        <div
          class="group max-w-lg p-3 rounded-b transition duration-500"
          :class="{
            'rounded-tl ml-4 order-2 bg-indigo-50': self,
            'rounded-tr mr-4 bg-gray-50': !self,
          }"
        >
          <p
            class="outline-none text-black opacity-60 text-sm font-normal leading-5"
          >
            {{ message.content }}
          </p>
        </div>

        <div :class="self ? ['ml-4', 'order-1'] : ['mr-4']">
          <p
            class="outline-none text-xs text-black opacity-60 font-light whitespace-pre"
          >
            {{ createdAt }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
