import {
  IconAdjustmentsHorizontal,
  IconArchiveBoxXMark,
  IconArrowDownTray,
  IconArrowLeft16Solid,
  IconArrowLongLeft20Solid,
  IconArrowLongRight20Solid,
  IconArrowRight16Solid,
  IconArrowUturnRight,
  IconBars3,
  IconBuildingOffice,
  IconChatBubbleLeftEllipsis,
  IconChatBubbleLeftRight,
  IconCheck,
  IconCheckCircle,
  IconChevronDown20Solid,
  IconCog6Tooth,
  IconCreditCard,
  IconDocumentDuplicate20Solid,
  IconEllipsisVerticalSolid,
  IconEnvelopeSolid,
  IconExclaimationTriangle,
  IconEye20Solid,
  IconEyeSlash20Solid,
  IconFolderOpen,
  IconHome,
  IconInformationCircle,
  IconMinusCircle,
  IconMoon,
  IconPencilSquare,
  IconPencilSquare20Solid,
  IconPlus,
  IconQueueList,
  IconRocketLaunch,
  IconSquare3Stack3d,
  IconSun,
  IconTrash,
  IconUserSolid,
  IconUsers,
  IconXCircle,
  IconXMark,
  IconPaperAirplane,
  IconShieldCheck,
  IconArrowUpOnSquare,
} from '@iconify-prerendered/vue-heroicons'
import {
  IconDriveDocument,
  IconFile,
  IconFilePdfBox,
  IconFilePresentationBox,
  IconGoogleSpreadsheet,
  IconImage,
  IconClockOutline,
} from '@iconify-prerendered/vue-mdi'

export const AdjustmentsHorizontalIcon = IconAdjustmentsHorizontal
export const ArchiveBoxXMarkIcon = IconArchiveBoxXMark
export const ArrowDownTrayIcon = IconArrowDownTray
export const ArrowLeftIcon = IconArrowLeft16Solid
export const ArrowLongLeftIcon = IconArrowLongLeft20Solid
export const ArrowLongRightIcon = IconArrowLongRight20Solid
export const ArrowRightIcon = IconArrowRight16Solid
export const ArrowUturnRightIcon = IconArrowUturnRight
export const Bars3Icon = IconBars3
export const BuildingOfficeIcon = IconBuildingOffice
export const ChatBubbleLeftEllipsisIcon = IconChatBubbleLeftEllipsis
export const ChatBubbleLeftRightIcon = IconChatBubbleLeftRight
export const CheckCircleIcon = IconCheckCircle
export const CheckIcon = IconCheck
export const ChevronDownIcon = IconChevronDown20Solid
export const Cog6ToothIcon = IconCog6Tooth
export const CreditCardIcon = IconCreditCard
export const DocumentDuplicateIcon = IconDocumentDuplicate20Solid
export const EllipsisVerticalIcon = IconEllipsisVerticalSolid
export const EnvelopeIcon = IconEnvelopeSolid
export const ExclamationTriangleIcon = IconExclaimationTriangle
export const EyeIcon = IconEye20Solid
export const EyeSlashIcon = IconEyeSlash20Solid
export const DriveDocumentIcon = IconDriveDocument
export const FileIcon = IconFile
export const FilePdfBoxIcon = IconFilePdfBox
export const FilePresentationBoxIcon = IconFilePresentationBox
export const GoogleSpreadsheetIcon = IconGoogleSpreadsheet
export const ImageIcon = IconImage
export const FolderOpenIcon = IconFolderOpen
export const HomeIcon = IconHome
export const InformationCircleIcon = IconInformationCircle
export const MinusCircleIcon = IconMinusCircle
export const MoonIcon = IconMoon
export const PencilSquareOutlineIcon = IconPencilSquare
export const PencilSquareSolidIcon = IconPencilSquare20Solid
export const PlusIcon = IconPlus
export const RocketLaunchIcon = IconRocketLaunch
export const Square3Stack3DIcon = IconSquare3Stack3d
export const SunIcon = IconSun
export const TrashIcon = IconTrash
export const UserIcon = IconUserSolid
export const UsersIcon = IconUsers
export const XCircleIcon = IconXCircle
export const XMarkIcon = IconXMark
export const PaperAirplaneIcon = IconPaperAirplane
export const ShieldCheckIcon = IconShieldCheck
export const ArrowUpOnSquareIcon = IconArrowUpOnSquare
export const ClockOutlineIcon = IconClockOutline
export const QueueListIcon = IconQueueList
