<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import type { ChatMessage } from '@papershift/api/src/chat'
import { XMarkIcon, PaperAirplaneIcon } from '@papershift/ui/src/icons'
import useChatStore from '@/stores/chat/chat.store'
import { useI18n } from '@papershift/locale/src/i18n'
import useModalToggler from '@papershift/ui/src/utils/use-modal-toggler'
import ChatMemberEdit from './chat-view/ChatMemberEdit.vue'
import ChatMemberAdd from './chat-view/ChatMemberAdd.vue'
import ChatMemberList from './chat-view/ChatMemberList.vue'
import { useInfiniteScroll, useScroll } from '@vueuse/core'
import useChat from './composables/use-chat'
import useAuthStore from '@/stores/auth/auth.store'
import { createChannel } from '@/utils/cable'

const MIN_TOP_DISTANCE = 128 // two messages - one message is 64 px high at the moment

const authStore = useAuthStore()
const { t } = useI18n()
const { isModalActive, toggleModal } = useModalToggler()

const messageContent = ref('')
const editingUserId = ref('')
const firstPagesLoaded = ref(false)

const messageInputRef = ref<HTMLTextAreaElement | null>(null)
const messagesContainerRef = ref<HTMLDivElement | null>(null)

const chatStore = useChatStore()
const chatMembers = computed(() => chatStore.chatMembers)

const { context } = useChat()

let channel: any = null

type BroadcastedData = {
  action: string
  message: ChatMessage
}

function subscribeChannel() {
  channel = createChannel(
    {
      channel: 'ChatChannel',
      chat_id: chatStore.currentChat!.id,
      user_id: authStore.user!.id,
    },
    { received: handleMessage }
  )
}

function closeChat() {
  channel.unsubscribe()
  chatStore.closeCurrentChat()
}

async function handleMessage(data: BroadcastedData) {
  const newMessage = data.message
  chatStore.prependMessage(chatStore.currentChat!.id, newMessage)

  await nextTick()
  messagesContainerRef.value!.lastElementChild!.scrollIntoView(false)
}

async function submit() {
  await chatStore.createChatMessage(
    chatStore.currentChat!.id!,
    messageContent.value.trim()
  )
  messageContent.value = ''
}

async function fetchChatMembers() {
  toggleModal(true)
  chatStore.fetchChatMembersWithRoles(chatStore.currentChat!.id)
}

function isFollowUp(message: ChatMessage, index: number) {
  return (
    chatStore.currentChatMessages[index - 1]?.author_id === message.author_id
  )
}

async function loadInitialMessages() {
  firstPagesLoaded.value = false

  await chatStore.fetchMessagesForCurrentChat(true)
  await nextTick()

  while (
    chatStore.currentChatCanLoadMoreMessages() &&
    // not enough items yet for useInfiniteScroll to take over
    messagesContainerRef.value!.scrollHeight <=
      messagesContainerRef.value!.clientHeight + MIN_TOP_DISTANCE
  ) {
    await chatStore.fetchMessagesForCurrentChat(false)
    await nextTick()
  }

  // do the initial scroll to bottom
  messagesContainerRef.value!.lastElementChild?.scrollIntoView(false)

  firstPagesLoaded.value = true
}

async function loadMoreMessages() {
  const preLoadHeight = messagesContainerRef.value!.scrollHeight

  await chatStore.fetchMessagesForCurrentChat(false)
  await nextTick()

  // restore the "scrolled distance from the bottom",
  // but only if the container has been scrolled all the way to the top -
  // if it hasn't been scrolled to the top fully, this happens automagically
  //
  // useScroll is used to do this check,
  // because useScroll is what useInfiniteScroll uses under the hood
  if (useScroll(messagesContainerRef).arrivedState.top) {
    const postLoadHeight = messagesContainerRef.value!.scrollHeight
    messagesContainerRef.value!.scrollBy({
      top: postLoadHeight - preLoadHeight,
      behavior: 'instant',
    })
  }
}

watch(messageInputRef, (el) => el?.focus())
watch(
  () => chatStore.currentChat?.id,
  async (currentChatId) => {
    if (currentChatId) {
      subscribeChannel()
      await loadInitialMessages()
    }
  }
)

useInfiniteScroll(messagesContainerRef, loadMoreMessages, {
  direction: 'top',
  distance: MIN_TOP_DISTANCE,
  canLoadMore: () =>
    firstPagesLoaded.value && chatStore.currentChatCanLoadMoreMessages(),
})
</script>

<template>
  <teleport to="body">
    <div
      class="pointer-events-none z-10 fixed inset-y-0 right-0 flex max-w-full pl-10"
    >
      <Transition
        appear
        enter-active-class="transform transition ease-in-out duration-800 sm:duration-500"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition ease-in-out duration-800 sm:duration-500"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <div
          v-if="chatStore.currentChat !== null"
          id="chat-view"
          class="chat pointer-events-auto w-screen max-w-md"
        >
          <div class="flex h-full flex-col bg-white py-6 shadow-xl">
            <div class="px-4 sm:px-6">
              <div class="flex items-start justify-between">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  {{ chatStore.currentChat?.subject }}

                  <sup class="font-medium text-gray-500">
                    {{ context(chatStore.currentChat?.context_type) }}
                  </sup>
                </h3>

                <div class="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    @click="closeChat()"
                  >
                    <span class="absolute -inset-2.5" />
                    <span class="sr-only">Close panel</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>

            <div class="mt-3 sm:mt-0 px-4 sm:px-6">
              <button
                class="text-sm text-pink-600 hover:text-pink-900 self-start"
                @click="fetchChatMembers()"
              >
                {{ t('participants.button') }}
              </button>
            </div>

            <Modal
              :is-active="isModalActive"
              :title="t('participants.modal.title')"
              @close="toggleModal(false)"
            >
              <ChatMemberEdit
                v-if="
                  chatStore.currentChat.permissions?.chat_membership?.manage
                "
                :chat-id="chatStore.currentChat.id"
                :user-id="editingUserId"
                :can-delete="
                  chatStore.currentChat.permissions?.chat_membership?.manage ??
                  false
                "
                @close="editingUserId = ''"
                @updated="fetchChatMembers()"
                @removed="fetchChatMembers()"
              />
              <div class="mt-5">
                <ChatMemberAdd
                  v-if="
                    chatStore.currentChat.permissions?.chat_membership?.manage
                  "
                  :chat-id="chatStore.currentChat.id"
                  @added="fetchChatMembers()"
                />
              </div>
              <div>
                <ChatMemberList
                  :members="chatMembers"
                  :can-edit="
                    chatStore.currentChat.permissions?.chat_membership
                      ?.manage ?? false
                  "
                  @cancel-click="toggleModal(false)"
                  @edit-click="editingUserId = $event"
                />
              </div>
            </Modal>

            <div
              ref="messagesContainerRef"
              class="chat-messages relative mt-6 flex-1 px-4 sm:px-6 overflow-y-scroll grow flex flex-col"
            >
              <ChatMessage
                v-for="(message, i) in chatStore.currentChatMessages"
                :key="message.id"
                class="mb-4 md:mb-0"
                :message="message"
                :is-follow-up="isFollowUp(message, i)"
              />
            </div>

            <div class="sticky bottom-0 px-6 flex border-t bg-white">
              <textarea
                ref="messageInputRef"
                v-model="messageContent"
                tabindex="1"
                class="mt-4 w-11/12 max-h-20 h-auto leading-tight p-3 pr-12 border-gray-50 bg-gray-50 rounded-md shadow-sm resize-none overflow-y-hidden scrollbar-hidden focus:border-transparent focus:ring-0 text-sm text-black/70"
                placeholder="Type a message"
                aria-label="Type a message"
                rows="1"
                cols="40"
                @keypress.enter.exact.prevent.stop="submit()"
              />
              <button
                type="button"
                class="ml-2 mt-6 h-8 w-8 bg-indigo-300 hover:bg-indigo-400 text-white rounded-full focus:outline-none flex items-center justify-center"
                aria-label="send message"
                @click="submit()"
              >
                <PaperAirplaneIcon class="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </teleport>
</template>

/* v8 ignore start */
<i18n locale="en">
participants:
  button:
    Participants
  modal:
    title:
      Manage Participants
</i18n>

<i18n locale="de">
participants:
  button:
    Teilnehmer
  modal:
    title:
      Teilnehmer
</i18n>
/* v8 ignore stop */
